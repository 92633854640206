import React, {useEffect, useState} from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ChevronDownIcon from '../assets/images/icons/chevron-down.svg';
import ChevronUpIcon from '../assets/images/icons/chevron-up.svg';
import {useSiteData} from '../hooks/useSiteData';
import {graphql, Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import {Container, Row, Col} from 'react-bootstrap';
import Seo from "../components/seo";
import InstagramIcon from '../assets/images/icons/logo_Instagram_Glyph_Gradient.png';
import Snowflakes from "magic-snowflakes";


export default function parents({data: {parents, allTestimonial, global}}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedTestimonial, setSelectedTestimonial] = useState();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

            const elements = document.querySelectorAll('.Testimonials.accordion .image-box');

            elements.forEach(function (element) {
                console.log(element);
                const container = element;
                const img = container.querySelector('img');
                const imgUrl = img ? img.src : null;

                if (imgUrl) {
                    container.style.backgroundImage = `url(${imgUrl})`;
                }
            });


    });



    return <Page orientation="portrait">

        <Seo remoteMetaData={parents} title={parents.titleSEO}/>


        <div className="game-box">
            <div className="game-box-inner">
                <header className="header-area">
                    <div className="container">
                        <div className="header-grid">
                            <div className="box-1">
                            </div>
                            <div className="box-2"/>

                            <Menu/>
                        </div>
                    </div>
                </header>

                <div className="content" style={{paddingBottom: 91.3333}}>
                    <div className="gen-page nav-grid">
                        <div className="logo-box">
                            <Link to="/">
                                <Logo/>
                            </Link>
                        </div>
                        <div className="button-box">
                            <a href="https://www.instagram.com/keenkidsglobal/" target="_blank"
                               className="ico-social-instagram-wrap">
                                <img src={InstagramIcon} alt="Instagram logo"
                                     className="ico-social-instagram"/>
                            </a>
                            <a href="https://shop.keenkids.com.au" target="_blank"
                               className="kk-btn-rounded btn-primary3">Shop</a>
                        </div>
                    </div>


                    <h1>{parents.header}</h1>


                    <Markdown>{parents.bodyText}</Markdown>

                    <section id="main-video" className="mt-5 mb-5">
                        <iframe width="364" height="204"
                                src="https://www.youtube.com/embed/edRVi6tZ6T4?rel=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </section>


                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h1>Testimonials</h1>


                        <Accordion onSelect={id => setSelectedTestimonial(id)} className="Testimonials">
                            {allTestimonial.edges.map(({node}) => {
                                const isSelected = selectedTestimonial === node.id;

                                return <Card key={node.id}>
                                    <Accordion.Toggle as={Card.Header} eventKey={node.id}
                                                      className={isSelected ? 'faq-open-header' : ''}>
                                        <div className="card-header" id="headingOne">
                                            <div className="image-box">
                                                <div className="thumb-wrapper">
                                                    <div className="cover">
                                                        <img src={node.image.localFile.publicURL} alt={node.altText}/>
                                                        {/*<GatsbyImage*/}
                                                        {/*    image={node.image.localFile.childImageSharp.gatsbyImageData}*/}
                                                        {/*    alt={node.altText}/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-box">
                                                {node.accordionHeader}
                                            </div>
                                            <div className="toggler">
                                                <img height={24} width={24}
                                                     src={isSelected ? ChevronUpIcon : ChevronDownIcon}/>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={node.id}>
                                        <Card.Body>
                                            <p className="testimonial-name">{node.header}</p>
                                            <GatsbyImage
                                                image={node.image.localFile.childImageSharp.gatsbyImageData}
                                                alt={node.altText}/>
                                            <Markdown className="mt-2">{node.bodyText}</Markdown>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>;
                            })}
                        </Accordion>
                        </Col>

                        {/*{allTestimonial.edges.map(({node}) => (*/}
                        {/*    <Col lg={12} md={12} sm={12} xs={12} key={node.id} className="py-3">*/}
                        {/*        /!* {node.base.split('-').join(' ').split('.')[0]} *!/*/}
                        {/*        <p className="testimonial-name">{node.header}</p>*/}
                        {/*        <a href={node.image.localFile.publicURL}>*/}
                        {/*            <GatsbyImage*/}
                        {/*                image={node.image.localFile.childImageSharp.gatsbyImageData}*/}
                        {/*                alt={node.altText}/>*/}
                        {/*        </a>*/}
                        {/*        <Markdown className="mt-2">{node.bodyText}</Markdown>*/}
                        {/*    </Col>*/}
                        {/*))}*/}
                    </Row>

                    <div className="text-center">
                        <Markdown>{parents.authorBodyText}</Markdown>
                    </div>
                    <div className="text-center mb-3 ">
                        <img src={parents.authorImage.localFile.publicURL} alt="Dr. Alexandra Bernhardi"/>
                    </div>
                    <div className="text-center">
                        <a href="/charlie-the-keen-kid-book/index.html" target="_blank"
                           className="kk-btn-rounded btn-primary3 buy-book">Read the Book For Free</a>
                        <a href={global.bookAmazonURL} target="_blank" className="kk-btn-rounded btn-primary3 buy-book">Buy
                            The Soft Cover</a>
                        <a href="https://shop.keenkids.com.au" target="_blank"
                           className="kk-btn-rounded btn-primary3 buy-book">Buy The Hard Cover - Australia only</a>
                    </div>
                    <Sponsor/>
                </div>
                {/* / .content */}
            </div>
            {/* / .game-box-inner */}
        </div>
        {/* / .game-box */}
    </Page>;
}

export const query = graphql`
    query {
        parents {
            header
            bodyText
            authorBodyText
            titleSEO
            descriptionSEO
            authorImage {
                localFile {
                    publicURL
                }
            }
            ogImage {
                localFile {
                    publicURL
                }
            }
        }
        global{
            bookAmazonURL
        }
        allTestimonial(
            filter: {published: {eq: true}, active: {eq: true}}
            sort: {fields: order, order: DESC}
        ) {
            edges {
                node {
                    accordionHeader
                    header
                    altText
                    active
                    bodyText
                    id
                    image {
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    height: 600
                                    width: 900
                                    transformOptions: {fit: COVER}
                                    placeholder: BLURRED
                                    webpOptions: {quality: 50}
                                )
                            }
                        }
                    }
                }
            }
        }

    }
`;
